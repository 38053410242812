import VALIDATE_ZIP_CODE from '@/gql/validateZipCode.graphql'
import { apolloClient } from '@/vue-apollo'
import IBAN from 'iban'

export default {
  required: (value) => !!value || 'Pflichtfeld',
  email: (value) => {
    if (!value) return true
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
      pattern.test(value) || 'Bitte geben Sie eine gültige E-Mail Adresse an!'
    )
  },
  iban: (value) => IBAN.isValid(value) || 'Keine gültige IBAN',
  bic: (v) => {
    const pattern =
      /^([a-zA-Z]){4}(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MC|MN|ME|MS|MA|MZ|MM|MA|NR|NP|NL|AN|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|SH|KN|LC|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SK|SI|SB|SO|ZA|GS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)([0-9a-zA-Z]){2}([0-9a-zA-Z]{0,3})$/
    return pattern.test(v) || 'Keine gültige BIC'
  },
  password: (v) => {
    const pattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/
    if (v) {
      return (
        pattern.test(v) ||
        'Passwort muss mindestens 8 Zeichen lang sein und mindestens ein Kleinbuchstabe, ein Großbuchstabe, ein Sonderzeichen und eine Ziffer enthalten.'
      )
    }
    return true
  },
  postalCode: (v) => {
    const postalRegex = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/
    return postalRegex.test(v) || 'Keine gültige Postleitzahl'
  },
  phonenumber: (v) => {
    return !!v
  },
  amount: (v) => {
    if (!v) {
      return true
    }
    const pattern = /^\d+(\.\d{1,2})?$/
    return pattern.test(v) || 'Ungülter Wert'
  },
  twoDecimals(value) {
    return value.match(/^\d*\.?\d?\d?$/)
      ? true
      : 'Maximal zwei Nachkommastellen erlaubt'
  },
  min: (min) => (value) => {
    return min <= parseInt(value,10) || `Darf nicht kleiner als ${min} sein`
  },
  maxLength: (maxLength) => (value) => {
    return maxLength > (!value ? 0 : value.length) || `Höchstens ${maxLength} Zeichen`
  },
  maxFactor: (max) => (value) => {
    return value <= max || `Maximaler Faktor: ${max}`
  },
  validateZipCode(countryCode) {
    return (value) => {
      const ERROR_MESSAGE = `Ungültige PLZ für ${countryCode}`
      if (!value) {
        return ERROR_MESSAGE
      }
      return new Promise((resolve) => {
        apolloClient
          .query({
            query: VALIDATE_ZIP_CODE,
            variables: {
              countryCode,
              zipCode: value,
            },
          })
          .then((response) => {
            if (response.data.validateZipCode) {
              resolve(true)
            } else {
              resolve(ERROR_MESSAGE)
            }
          })
      })
    }
  },
}
